// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_main__container__Qv\\+nJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 32px 0; */
  width: 1080px;
  margin: 0 auto;
}

.Main_main__container_top__dfMBg {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .Main_main__container__Qv\\+nJ {
    width: 100%;
  }

  .Main_main__container_top__dfMBg {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Main.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,8BAA8B;EAC9B,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;EACV;AACF","sourcesContent":[".main__container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  /* padding: 32px 0; */\n  width: 1080px;\n  margin: 0 auto;\n}\n\n.main__container_top {\n  display: flex;\n  /* align-items: center; */\n  justify-content: space-between;\n  gap: 24px;\n  width: 100%;\n}\n\n@media screen and (max-width: 768px) {\n  .main__container {\n    width: 100%;\n  }\n\n  .main__container_top {\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main__container": `Main_main__container__Qv+nJ`,
	"main__container_top": `Main_main__container_top__dfMBg`
};
export default ___CSS_LOADER_EXPORT___;
