// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contacts_contacts__container_item__r\\+xT7 {
  padding-bottom: 8px;
}

.Contacts_contacts__container_item__r\\+xT7 input {
  border-radius: 90px;
  border: 2px solid #eee;
  background: #fff;
  padding: 14px 16px;
  width: 340px;
}

.Contacts_contacts__container__Eqa4L button {
  background: #18959d;
  color: #fff;
  margin-top: 8px;
  border: none;
  border-radius: 90px;
  padding: 14px 56px;
  cursor: pointer;
  width: 200px;
}

.Contacts_contacts__container__button_group__Xm0zc {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;
}

.Contacts_contacts__container__button_save__Tggl2 {
  background: #18959d;
  border: none;
  border-radius: 90px;
  padding: 14px 56px;
  cursor: pointer;
  color: #fff;
  width: 50%;
}

.Contacts_contacts__container__button_cancel__kxczQ {
  background: var(--button-passive);
  border: none;
  border-radius: 90px;
  padding: 14px 56px;
  cursor: pointer;
  width: 50%;
}

.Contacts_contacts__container__button_edit__PGNtv {
  background: #18959d;
  border: none;
  border-radius: 90px;
  padding: 14px 56px;
  cursor: pointer;
  color: #fff;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Contacts.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,WAAW;AACb","sourcesContent":[".contacts__container_item {\n  padding-bottom: 8px;\n}\n\n.contacts__container_item input {\n  border-radius: 90px;\n  border: 2px solid #eee;\n  background: #fff;\n  padding: 14px 16px;\n  width: 340px;\n}\n\n.contacts__container button {\n  background: #18959d;\n  color: #fff;\n  margin-top: 8px;\n  border: none;\n  border-radius: 90px;\n  padding: 14px 56px;\n  cursor: pointer;\n  width: 200px;\n}\n\n.contacts__container__button_group {\n  display: flex;\n  justify-content: space-between;\n  gap: 8px;\n  margin-top: 8px;\n}\n\n.contacts__container__button_save {\n  background: #18959d;\n  border: none;\n  border-radius: 90px;\n  padding: 14px 56px;\n  cursor: pointer;\n  color: #fff;\n  width: 50%;\n}\n\n.contacts__container__button_cancel {\n  background: var(--button-passive);\n  border: none;\n  border-radius: 90px;\n  padding: 14px 56px;\n  cursor: pointer;\n  width: 50%;\n}\n\n.contacts__container__button_edit {\n  background: #18959d;\n  border: none;\n  border-radius: 90px;\n  padding: 14px 56px;\n  cursor: pointer;\n  color: #fff;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts__container_item": `Contacts_contacts__container_item__r+xT7`,
	"contacts__container": `Contacts_contacts__container__Eqa4L`,
	"contacts__container__button_group": `Contacts_contacts__container__button_group__Xm0zc`,
	"contacts__container__button_save": `Contacts_contacts__container__button_save__Tggl2`,
	"contacts__container__button_cancel": `Contacts_contacts__container__button_cancel__kxczQ`,
	"contacts__container__button_edit": `Contacts_contacts__container__button_edit__PGNtv`
};
export default ___CSS_LOADER_EXPORT___;
