// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Location_location__select__f2oLv {
  border-radius: 90px;
  border: 2px solid #eee;
  background: #fff;
  padding: 14px 16px;
  width: 340px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Location.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".location__select {\n  border-radius: 90px;\n  border: 2px solid #eee;\n  background: #fff;\n  padding: 14px 16px;\n  width: 340px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location__select": `Location_location__select__f2oLv`
};
export default ___CSS_LOADER_EXPORT___;
