// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__jWCji {
  display: flex;
  justify-content: space-around;
}

.Header_header__jWCji h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 33.6px */
  letter-spacing: 0.56px;
}

.Header_header__jWCji p {
  text-align: right;
  font-size: 14px;
  font-weight: 300;
  line-height: 110%; /* 15.4px */
}

hr {
  margin: 32px 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;EAC9B,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,iBAAiB,EAAE,WAAW;AAChC;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-around;\n}\n\n.header h1 {\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 120%; /* 33.6px */\n  letter-spacing: 0.56px;\n}\n\n.header p {\n  text-align: right;\n  font-size: 14px;\n  font-weight: 300;\n  line-height: 110%; /* 15.4px */\n}\n\nhr {\n  margin: 32px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__jWCji`
};
export default ___CSS_LOADER_EXPORT___;
