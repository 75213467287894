// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_auth__container__uDLfw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
}

.Auth_auth__container_form__xEy4h {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Auth_auth__container_form__xEy4h input {
  border-radius: 90px;
  border: 2px solid #eee;
  background: #fff;
  padding: 14px 16px;
  width: 340px;
}

.Auth_auth__container_form__xEy4h button {
  border-radius: 90px;
  background: #18959d;
  color: #fff;
  border: none;
  padding: 14px 56px;
  margin-top: 8px;
  cursor: pointer;
}

.Auth_auth__container_form__xEy4h button:hover {
  background: #13737a;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Auth.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".auth__container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 32px 0;\n}\n\n.auth__container_form {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.auth__container_form input {\n  border-radius: 90px;\n  border: 2px solid #eee;\n  background: #fff;\n  padding: 14px 16px;\n  width: 340px;\n}\n\n.auth__container_form button {\n  border-radius: 90px;\n  background: #18959d;\n  color: #fff;\n  border: none;\n  padding: 14px 56px;\n  margin-top: 8px;\n  cursor: pointer;\n}\n\n.auth__container_form button:hover {\n  background: #13737a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth__container": `Auth_auth__container__uDLfw`,
	"auth__container_form": `Auth_auth__container_form__xEy4h`
};
export default ___CSS_LOADER_EXPORT___;
