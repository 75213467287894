// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__container__\\+vmrb {
  padding: 32px 0;
  width: 100%;
  /* border: 2px solid black; */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Card.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,6BAA6B;AAC/B","sourcesContent":[".card__container {\n  padding: 32px 0;\n  width: 100%;\n  /* border: 2px solid black; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card__container": `Card_card__container__+vmrb`
};
export default ___CSS_LOADER_EXPORT___;
