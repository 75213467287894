// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/positypeneue.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/positypeneue.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/positypeneue.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/weblysleekuil.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/weblysleekuil.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/weblysleekuil.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "PosiTypeNeue";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("ttf"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "WeblySleek";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("ttf"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");
  font-weight: normal;
  font-style: normal;
}

:root {
  --button-active: #18959d;
  --button-passive: rgba(24, 149, 157, 0.08);
}

body {
  background-color: #f8fffe;
  font-family: "WeblySleek";
  color: #333;
}

h1,
h2,
h3 {
  font-family: "PosiTypeNeue";
}

h1 {
  text-align: right;
}

select {
  border-radius: 90px;
}

input[type="text"] {
  border-radius: 90px;
}

hr {
  color: #e7e7e7;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B;;2DAEoD;EACpD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB;;2DAEqD;EACrD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,0CAA0C;AAC5C;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;AACb;;AAEA;;;EAGE,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@font-face {\n  font-family: \"PosiTypeNeue\";\n  src: url(\"../fonts/positypeneue.ttf\") format(\"ttf\"),\n    url(\"../fonts/positypeneue.woff\") format(\"woff\"),\n    url(\"../fonts/positypeneue.woff2\") format(\"woff2\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: \"WeblySleek\";\n  src: url(\"../fonts/weblysleekuil.ttf\") format(\"ttf\"),\n    url(\"../fonts/weblysleekuil.woff\") format(\"woff\"),\n    url(\"../fonts/weblysleekuil.woff2\") format(\"woff2\");\n  font-weight: normal;\n  font-style: normal;\n}\n\n:root {\n  --button-active: #18959d;\n  --button-passive: rgba(24, 149, 157, 0.08);\n}\n\nbody {\n  background-color: #f8fffe;\n  font-family: \"WeblySleek\";\n  color: #333;\n}\n\nh1,\nh2,\nh3 {\n  font-family: \"PosiTypeNeue\";\n}\n\nh1 {\n  text-align: right;\n}\n\nselect {\n  border-radius: 90px;\n}\n\ninput[type=\"text\"] {\n  border-radius: 90px;\n}\n\nhr {\n  color: #e7e7e7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
