// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Users_users__container__eu0e0 {
}

.Users_users__container__eu0e0 button {
  background: #18959d;
  border: none;
  border-radius: 90px;
  padding: 14px 56px;
  cursor: pointer;
  color: #fff;
  width: 100%;
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Users.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".users__container {\n}\n\n.users__container button {\n  background: #18959d;\n  border: none;\n  border-radius: 90px;\n  padding: 14px 56px;\n  cursor: pointer;\n  color: #fff;\n  width: 100%;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"users__container": `Users_users__container__eu0e0`
};
export default ___CSS_LOADER_EXPORT___;
